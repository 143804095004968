<template>
  <moe-page title="设备型号管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="equipmentSearch">
      <el-form-item label="设备名称">
        <el-input v-model.trim="equipmentParams.name" placeholder="输入设备名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="设备型号">
        <el-input v-model.trim="equipmentParams.productModel" placeholder="输入设备型号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="无线类型">
        <el-select v-model="equipmentParams.wireless" placeholder="请选择无线类型" clearable>
          <el-option v-for="(item, key) in wirelessList" :key="key" :label="key" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <moe-select type="statusList" v-model="equipmentParams.enable" placeholder="选择状态" clearable />
      </el-form-item>
    </moe-inquire>
    <!-- 设备信息 -->
    <moe-table ref="equipmentTable" url="/deviceModel/paging" :params="equipmentParams">
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/smart-equipment/add')">
          添加设备
        </el-button>
      </template>
      <el-table-column prop="name" label="设备名称" min-width="130" />
      <el-table-column prop="productModel" label="设备型号" min-width="130" />
      <el-table-column prop="wireless" label="无线类型" width="80">
        <template slot-scope="{ row }">
          {{ wirelessComputed[row.wireless] }}
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="状态" width="80">
        <template slot-scope="{ row }">
          {{ row.enable ? '启用' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column prop="empty" label="设备图片" width="130">
        <template slot-scope="{ row }">
          <moe-image :src="row.homeImage" width="100px" height="50px" />
        </template>
      </el-table-column>
      <el-table-column prop="empty" label="通用图片" width="130">
        <template slot-scope="{ row }">
          <moe-image :src="row.image" width="100px" height="50px" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns df aic jcc" slot-scope="{ row }">
          <el-button type="info" size="mini" icon="el-icon-document" @click="$router.push(`/smart-equipment/params?id=${row.id}`)">参数配置</el-button>
          <el-button type="success" size="mini" icon="el-icon-view" @click="$router.push(`/smart-equipment/detail?id=${row.id}`)">详情</el-button>
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/smart-equipment/amend?id=${row.id}`)">修改</el-button>
          <el-button type="success" size="mini" icon="el-icon-upload" @click="$router.push(`/smart-equipment/firmware?id=${row.id}&wireless=${row.wireless}`)">上传固件</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'SmartEquipmentList',
  data() {
    //设备筛选信息
    let equipmentParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      productModel: '',
      name: '',
      wireless: '',
      enable: '',
    };

    return {
      equipmentParams, //设备筛选信息
      wirelessList: {}
    };
  },
  computed: {
    wirelessComputed() {
      let options = {}
      if (!this.wirelessList) {
        return '-'
      }

      for (let key in this.wirelessList) {
        options[this.wirelessList[key]] = key
      }

      return options
    }
  },
  methods: {
    equipmentSearch(isSearch) {
      if (!isSearch) {
        this.equipmentParams = {
          pageNum: 1,
          pageSize: 10,
          productModel: '',
          name: '',
          wireless: '',
          enable: '',
        }
      }

      this.$refs['equipmentTable'].searchData();
    }
  },
  mounted() {
    /** 可用参数 */
    this.$moe_api.EQUIPMENT_API.getDeviceModelParams().then((data) => {
      if (data.code === 200) {
        this.wirelessList = data.result.wireless;
      } else {
        this.$moe_msg.error(data.message)
      }
    })
    /** 可用参数 */
  }
};
</script>
